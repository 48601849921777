<!-- eslint-disable import/no-unresolved -->
<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-4 ">
      <div>
        <v-card-title>
          <span class="tw-break-words"><p>{{ title }}</p></span>
        </v-card-title>
        <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
      </div>
      <v-avatar
        color="primary"
        size="48"
        class="v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          color="success"
        >
          {{ icons.mdiGiftOpenOutline }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="visitStore.visitData.samples"
        :items-per-page="5"
        item-key="product"
      ></v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-card-text>
        <i>*Free Delivery with prescriptions filled by PharmaSmart</i>
      </v-card-text>
      <v-spacer></v-spacer>
      <!-- <v-btn
          color="primary"
          @click="submit"
        >
          Select
        </v-btn> -->
    </v-card-actions>
  </div>
</template>

<script>
import { mdiGiftOpenOutline } from '@mdi/js'
import { useVisitStore } from '@/stores/Visit'

export default {
    name: 'SamplesTable',
    setup() {
        const visitStore = useVisitStore()

        return {
            visitStore,
            icons: {
                mdiGiftOpenOutline,
            },
        }
    },
    data() {
        return {
            title: 'Recommended Products',
            subtitle: "You're eligible for upto 3 free skincare samples as recommended by your doctor",
            headers: [
                {
                    text: 'Brand',
                    value: 'brand',
                },
                {
                    text: 'Product Name',
                    value: 'product',
                },
            ],
        }
    },
}
</script>
