<template>
  <div>
    <v-row class="match-height">
      <v-tour
        name="selectedTour"
        :steps="selectedSteps"
        :options="myOptions"
        :callbacks="myCallbacks"
      ></v-tour>
      <v-col
        cols="12"
        md="12"
        sm="12"
      >
        <home-page-banner
          :is-main-bio-dialog-open="activateSignup"
        ></home-page-banner>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <v-card>
          <visit-card></visit-card>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <v-card>
          <samples-table></samples-table>
        </v-card>
      </v-col>
      <!-- <v-col
        cols="12"
        md="4"
        sm="12"
      >
        <transfer-card></transfer-card>
      </v-col> -->
    </v-row>
    <router-view></router-view>
  </div>
</template>

<script>

import { ref } from '@vue/composition-api'
import { useVisitStore } from '@/stores/Visit.js'

// eslint-disable-next-line import/named
import { getInitials } from '@/util/functions/index.js'
import VisitCard from '@/components/UI/VisitCard.vue'
import homePageBanner from '@/components/marketing/homePageBanner.vue'
import { SamplesTable } from '@/components/UI/index.js'
import { homeTour } from '@/assets/tours/index.js'
import { visitService } from '@/api/index.js'

export default {
    name: 'Visit',
    components: {
        SamplesTable,
        VisitCard,
        homePageBanner,
    },
    setup() {
        const tourStart = ref(false)
        const visitStore = useVisitStore()

        return {
            tourStart,
            visitStore,
        }
    },
    data() {
        return {
            myOptions: {
                debug: true,
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish',
                },
            },
            myCallbacks: {
                onFinish: this.onFinish,
                onNextStep: this.myCustomNextStepCallback,
                onPreviousStep: this.myCustomPreviousStepCallback,

            // FIXME Enable when making tours for Filter Bar and check the items in closeCustomizer and OpenCustomizer in ApexFilterBar
            // onStart: this.onStart,
            // onSkip: this.onSkip,
            // onStop: this.onSkip,
            },

            // Tours
            homeTour,
            tourEnd: false,
            selectedSteps: [],
        }
    },
    computed: {
        patientInitials() {
            return getInitials(this.visitStore.patientData.name)
        },
        activateSignup() {
            if (this.visitStore.patientData?.detailedAddress) {
                return false
            }

            // this.$root.$emit('updateMainBioDialog', true)

            return true
        },
    },
    mounted() {
    // this.getFromLocalStorage()
        setTimeout(() => {
            if (this.activateSignup) {
            // wait 2 seconds
                setTimeout(() => {
                    this.$root.$emit('updateMainBioDialog', true)
                }, 500)

            // this.$root.$emit('updateMainBioDialog', true)
            }
        }, 1000)
        this.selectedSteps = this.homeTour
        this.$root.$on('tour', tour => {
            this.selectedSteps = this[tour.tour]
            this.videoId = tour.videoId
            this.dialogDescription = tour.description
            this.dialogTitle = tour.title
            this.tourStart = true
            this.startTour()
        })
    },
    destroyed() {
        this.$root.$off('tour')
    },
    methods: {

        myCustomNextStepCallback(currentStep) {
            if (currentStep === 0 && this.$route.path === '/order/genericorder') {
                this.$root.$emit('generic-order-tour-select-all')
            }
            if (currentStep === 8 && this.$route.path === '/order/genericorder') {
                this.$root.$emit('generic-order-tour-next-page', 2)
            }
        },
        myCustomPreviousStepCallback(currentStep) {
            if (currentStep === 10 && this.$route.path === '/order/genericorder') {
                this.$root.$emit('generic-order-tour-previous-page', 1)
            }
        },
        onSkip() {
            localStorage.removeItem('tempFilterBarOn')
        },
        onFinish() {
            this.tourEnd = true
            localStorage.removeItem('tempFilterBarOn')
        },
        onStart() {
            localStorage.setItem('tempFilterBarOn', false)
        },
        startTour() {
            this.tourStart = false
            this.$tours.selectedTour.start()
        },
        getFromLocalStorage() {
            const form = JSON.parse(localStorage.getItem('form'))
            this.visitStore.setVisit({ id: form.id, dob: form.dob })
        },
        async refreshData() {
            await this.visitStore.setVisit({ id: this.visitStore.getForm.id, dob: this.visitStore.getForm.dob })
            this.formKey += 1
        },
    },
}
</script>
<style lang="scss" scoped>
.v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}

.row.match-height {
  .v-card {
    height: 100%;
  }
}
</style>
