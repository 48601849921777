// Signoff: Thank you for chceking out this feature. If you have any further questions, please contact us using intercom or emailing us at info@pharmaguide.ca

const homeTour = [{
    target: '#home-content2',
    content: 'Here you can find the status of your Prescription',
    params: {
        placement: 'bottom',
        highlight: true,
        enableScrolling: false
    }
},
]

export default homeTour
