<template>
  <div>
    <div class="d-flex align-center justify-space-between ">
      <div>
        <v-card-title class="text-no-wrap">
          Prescription Details
        </v-card-title>
        <v-card-subtitle>Consultation Summary</v-card-subtitle>
      </div>
      <v-avatar
        color="primary"
        size="48"
        class="v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          color="primary"
        >
          {{ icons.mdiBottleTonicPlusOutline }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text class="d-flex align-center flex-wrap text-no-wrap">
      <div class="finance-summery-left-content-width">
        <span>Patient Name</span>
        <h2 class="text-l font-weight-semibold">
          {{ visitStore.patientData.name }}
        </h2>
      </div>

      <div class="finance-summery-right-content-width ">
        <span>Prescription Date</span>
        <h2 class="text-l font-weight-semibold">
          {{ visitStore.visitData.rxDate }}
        </h2>
      </div>
    </v-card-text>

    <v-card-text class="d-flex align-center flex-wrap ">
      <div class=" ">
        <span>Rx</span>
        <h2 class="text-l font-weight-semibold">
          <div>
            <v-list>
              <v-list-item
                v-for="item,index in parseRx"
                :key="item"
              >
                <v-list-item-content>
                  {{ index+1 }}:  {{ item }}
                  <!-- <v-list-item-title>{{ this.visitData[0].visitData.rx }}</v-list-item-title> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </h2>
      </div>
    </v-card-text>

    <v-card-text class="d-flex align-center flex-wrap text-no-wrap">
      <div class="finance-summery-left-content-width ">
      </div>

      <div class="finance-summery-right-content-width ">
        <span>Doctor</span>
        <h2 class="text-l font-weight-semibold">
          {{ visitStore.docData.name }}
        </h2>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { mdiBottleTonicPlusOutline } from '@mdi/js'

// import useVuetify from '@core/utils/vuetify'
import { useVisitStore } from '@/stores/Visit'

export default {
    name: 'VisitCard',
    setup() {
    // const progress = ref(75)
    // const { rootThemeClasses } = useVuetify()
        const visitStore = useVisitStore()

        return {
            visitStore,

            //   progress,
            //   rootThemeClasses,
            icons: {
                mdiBottleTonicPlusOutline,
            },
        }
    },
    data() {
        return {
            selectedSamples: [],
        }
    },
    computed: {
        parseRx() {
            //   const transform = this.visitData[0].visitData.rx.split('\n').map(item => item.split(':'))

            const transform = this.visitStore.visitData.rx ? this.visitStore.visitData.rx.split('\n') : []

            return transform
        },
    },
    methods: {
        limiter(e) {
            if (e.length > 3) {
                this.selectedSamples = e.slice(0, 3)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.finance-summery-left-content-width {
  width: 70%;
}
.finance-summery-right-content-width {
  width: 176px;
}
</style>
