<template>
  <div>
    <v-card color="text-center">
      <v-card-text class="d-flex flex-column justify-center align-center">
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mt-10"
          icon
          size="50"
        >
          <v-icon
            size="2rem"
            color="grey"
          >
            {{ icons.mdiFax }}
          </v-icon>
        </v-avatar>
        <h6 class="text-xl mt-4 font-weight-medium">
          Send prescription to another pharmacy
        </h6>
      </v-card-text>
      <v-card-text>
        Fax prescription to another pharmacy. (Pharmacy fax number required)
      </v-card-text>
      <v-card-text>
        <v-btn
          :to="{ name: 'transfer'}"
          color="primary"
          class="mt-4"
        >
          Fax now
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import useVuetify from '@core/utils/vuetify'
import {
    mdiFax,
} from '@mdi/js'

export default {
    name: 'RxTransferCard',
    components: {
    },
    setup() {
        const { rootThemeClasses } = useVuetify()

        return {
            rootThemeClasses,
            icons: {
                mdiFax,
            },
        }
    },
}
</script>
